import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { API } from "../../lib/api";
import dayjs from "dayjs";
import Feedback from "../../components/Feedback/Feedback"
import { useNavigate, useSearchParams } from "react-router-dom";

const Content = () => {
    const { lid } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const oid = queryParams.get('oid'); // Extracts 'oid' from the query string
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    const [line, setLine] = useState({})
    const [productGroup, setName] = useState("")
    const [lineComments, setComments] = useState("")
    const [bookedOrdersStage, setStage] = useState("")
    const [amount, setAmount] = useState(0)
    const [shipDate, setShipDate] = useState("")
    const [feedback,setFeedback] = useState({open: false, message: "", type: ""})

    const lineTypes = ['ABS Root Product Group', 'Buyout', 'Components', 'Equipment', 'Installation', 'LIAD', 'Moretto', 'Service', 'Silos', 'Spare Parts', 'TM - Components', 'TM - Equipment', 'TM - Installation', 'TM - Spare Parts', 'TM - Warranty Parts', 'TM Products Group', 'Warranty Parts']
    const stages = ['01 - Assign Resources', '02 - Submittals Sent', '03 - Submittals Approved', '04 - Shipped', '05 - Installed', '06 - Commissioned', '07 - Revenue Taken', '10 - Closed']

    const handleSubmit = async () => {
        const tempData = { 
            ...(productGroup && { productGroup }),
            ...(lineComments && { lineComments }),
            ...(bookedOrdersStage && { bookedOrdersStage }),
            ...(amount && { amount }),
            ...(shipDate && { shipDate }),
        }

        const response = await API.Lines.UpdateSingle(lid, oid, tempData, localStorage.token)
        if (response.status === 200) {
            console.log(response)
            setFeedback({open: true, message: response.data.message, type: "success"})
        } else {
            setFeedback({open: true, message: `Failed to update Opportunity Line: ${line.name}`, type: "error"})
        }
    }

    const handleReturnToOpp = () => {
        const oid = searchParams.get("oid")
        navigate(`/opportunities/${oid}`);
    }


    useEffect( ()=> {
        async function getData() {
            const response = await API.Lines.FetchById(lid, localStorage.token)
            setLine(response)


            response?.productGroup && setName(response.productGroup)
            response?.lineComments && setComments(response.lineComments)
            response?.bookedOrdersStage && setStage(response.bookedOrdersStage)
            response?.amount && setAmount(response.amount)
            response?.shipDate && setShipDate(response.shipDate)
            console.log(response)
        }

        getData()
    },[])

    return (
        <>
            <Feedback props={feedback} setFeedback={setFeedback} />
            <div className="col-lg-6">
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">Revenue Line - <a href="#">{line && line.lineComments}</a></h5>
                    </div>
                    <div className="card-body">
                        <h6 style={{margin: "1em"}}>Update Revenue Line</h6>
                        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: "space-evenly"}}>
                            <div className='col-10 col-lg-5' style={{marginBottom: "1em"}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={productGroup}
                                        label="Type"
                                        onChange={(e) => setName(e.target.value)}
                                    >
                                        {lineTypes.map(type => (
                                            <MenuItem key={type} value={type}>{type}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-10 col-lg-5' style={{marginBottom: "1em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        value={lineComments}
                                        onChange={(e) => setComments(e.target.value)}
                                        label="Line Description"
                                    />
                                </FormControl>
                            </div>
                            <div className='col-10 col-lg-5' style={{marginBottom: "1em"}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Booked Orders Stage</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={bookedOrdersStage}
                                        label="Booked Orders Stage"
                                        onChange={(e) => setStage(e.target.value)}
                                    >
                                        {stages.map(stage => (
                                            <MenuItem key={stage} value={stage}>{stage}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-10 col-lg-5' style={{marginBottom: "1em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        label="Amount"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                            {/* <div className="col-10 col-lg-5" style={{marginBottom: "1em"}}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        sx={{marginBottom: "1em"}}
                                        label="Ship Date"
                                        value={shipDate ? dayjs(shipDate) : null} // Ensure valid dayjs object
                                        onChange={(date) => setShipDate(date)}
                                        // onChange={(date) => updateField(field.name, date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                                </FormControl>                                
                            </div> */}
                        </div>

                        <div style={{display: "flex", justifyContent: "center", marginBottom: "2em"}}>
                            <Button variant="outlined" onClick={handleSubmit}>Save Changes</Button>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", marginBottom: "2em"}}>
                            <Button variant="outlined" color="warning" onClick={handleReturnToOpp}>Return to Opportunity</Button>
                        </div>

                        {/* <div style={{display: "flex", justifyContent: "center", marginBottom: "2em"}}>
                            <Button variant="outlined" href={`/opportunities/${oid}`}>View Opportunity</Button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Content